.wizard {
  .stepper-container {
    background-color: $lightgrey-light;

    .MuiPaper-root {
      margin: 0 auto;
      padding: 40px 20px 20px;
      background-color: $lightgrey-light;
      width: 70%;
    }

    .steppper-header {
      display: none;
    }
  }

  .content {
    max-width: 800px;
    padding: 50px 0;
    margin: 0 auto;

    .contact {
      font-family: "'LatoBold'";
      font-size: 16px;
      color: $black-base;

      .email-phone-link {
        color: $blue-base;
      }
    }
  }
  .content:has(.offers-container) {
    max-width: 1050px;
  }
}
.additional-info-form {
  .housing-payment-input {
    margin-bottom: 16px !important;
    .input-wrapper {
      margin-top: 7px;
    }
  }
}
.prime-prequal-approval {
  .offer-details-outer {
    box-shadow: 0px 4px 20px 0px rgba($black-base, 0.08);
    border-radius: 8px;
    border: 1px solid $lightgrey-base;
    border-top: none;
    .offer-details {
      border-radius: 6px;
      border-top: 6px solid $blue-base;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 32px 0;
      .column-left,
      .column-right {
        height: 142px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .column-left {
        width: calc(60% - 1px);
        border-right: 1px solid $grey-base;
        padding-top: 10px;

        .offer-details-row {
          width: 75%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      .column-right {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .imprint-logo {
          width: 100%;
          max-width: 500px;
          padding: 32px;
        }
      }
    }
  }
  .disclaimer-text {
    margin: 4px 0 0 16px;
  }
  .higher-approval-text {
    margin-top: 20px;
    background-color: $lightblue-base;
    padding: 16px;
  }
  .disclaimer-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 32px 0;
    .icon-container {
      aspect-ratio: 1/1;
      width: 36px;
      height: 36px;
      background-color: $lightblue-base;
      border-radius: 50%;
      padding: 8px;
      margin-right: 8px;
    }
  }
  .consent-to-offers {
    .consent-header {
      background-color: $black-base;
    }
  }
  button.accept-offer {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .view-more-offers {
    margin-left: 18px;
    margin-top: 16px;
    @media (max-width: 768px) {
      margin-left: 0;
      text-align: center;
    }
  }
}
@media (max-width: 768px) {
  .additional-info-form {
    .one-line-form {
      flex-direction: column;
    }
    .housing-payment-input {
      margin-bottom: 24px !important;
    }
    p {
      margin-top: 24px;
    }
  }
}

@media (max-width: 1150px) {
  .prime-prequal-approval {
    .offer-details-outer {
      .offer-details {
        flex-direction: column;
        padding: 0;
        .column-left {
          height: fit-content;
          width: 100%;
          padding: 20px 0;
          border-right: none;
          border-bottom: 1px solid $grey-base;
        }
        .column-right {
          width: 100%;
          padding: 0;
          .imprint-logo {
            max-width: 400px;
            width: 100%;
            padding: 32px;
          }
        }
      }
    }
  }
}

.preparing-offer-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0 20px 20px 20px;
  .loading-bar {
    height: 6px;
    width: 100%;
    background: no-repeat linear-gradient($blue-base 0 0),
      no-repeat linear-gradient($blue-base 0 0), $lightgrey-base;
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }

  @keyframes l16 {
    0% {
      background-position: -150% 0, -150% 0;
    }
    66% {
      background-position: 250% 0, -150% 0;
    }
    100% {
      background-position: 250% 0, 250% 0;
    }
  }
}

.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;

  @media (max-width: 575.98px) {
    display: initial;
  }
}

.application-instructions {
  font-family: "'LatoBold'";
  font-size: 20px;
  color: $black-base;

  .info-header {
    margin-bottom: 16px;

    span {
      color: $red-base;
    }

    p {
      font-family: inherit;
      font-size: inherit;
      line-height: 2rem;
    }
  }

  ul.info-content {
    font-family: inherit;
    font-size: inherit;

    li {
      font-size: inherit;
    }

    a {
      color: $blue-base;
      font-size: inherit;
      font-style: italic;
    }

    button {
      background: none;
      font-style: italic;
      font-family: 'LatoRegular';
      font-weight: bold;
      font-size: 20px;
      border: none;
      padding: 0;
      color: $blue-base;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .MuiIconButton-root {
    padding-left: 4px;
    padding: 0;
  }
}

.info-contain {
  font-family: "'LatoBold'";
  font-weight: bold;
  font-size: 20px;
  color: $black-base;
}

.social-security {
  display: flex;
  margin-bottom: 3rem !important;
  svg {
    color: $darkgrey-base;
  }
  p {
    font-family: 'LatoRegular';
    color: $darkgrey-base;
  }
}

.forms-container {
  margin-top: 50px;

  > .MuiTextField-root {
    width: 100%;
    margin-bottom: 70px;
  }

  #standard-basic {
    font-family: 'LatoRegular';
    font-size: 16px;
  }

  .one-line-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    // > div {
    //   width: 50%;
    // }
  }

  .phone-number-helper {
    font-weight: 700;
    margin-bottom: 50px;
  }

  .mobile-number {
    margin-bottom: 30px !important;
  }

  .home-number {
    margin-bottom: 30px;
    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        margin-top: 6px;
      }
      .checkbox {
        width: 100%;
      }
    }
  }

  .gray-icon {
    color: $darkgrey-base;
  }

  .date-birth {
    width: 50%;

    > div {
      width: 100%;
    }
  }

  .annual-income {
    margin-top: 50px;
  }

  .indented-income {
    margin-top: 50px;
  }

  .input-tooltip {
    margin-bottom: 50px;
    margin-top: -35px;
  }

  .checkbox {
    padding-top: 12px;
    width: 6%;
  }

  h4 {
    font-family: 'LatoRegular';
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: -30px;
  }

  .prefilledGroup {
    font-family: 'LatoRegular';
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;

    .prefilled-field {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;

      .prefilled-label {
        width: 25%;
        font-weight: bold;
      }

      .prefilled-value {
        width: 70%;
        line-height: 1.7rem;
        margin-right: 30px;
        margin-left: 15px;
      }
    }
  }
}

.pre-qualification-installment .edit-button {
  display: flex;
  margin-left: -15px;
  cursor: pointer;

  span {
    display: flex;
    cursor: pointer;
    align-items: center;
    column-gap: 5px;
    padding: 10px 0px;
  }

  p {
    font-family: 'LatoRegular';
    color: $red-base;
    opacity: '1' !important;
    width: '100% !important';
    font-size: inherit;
    margin: 0;
  }

  img {
    height: 15px;
    width: 15px;
    vertical-align: 'bottom';
  }
}

.terms-and-condition {
  margin-top: 35px;
  margin-bottom: 40px;

  p {
    font-family: 'LatoRegular';
    color: $black-base;
    font-size: 16px;
  }

  strong {
    font-family: "'LatoBold'";
  }
}

.pre-qualification {
  .checkbox {
    margin-top: 10px !important;
  }

  .must-read {
    color: $black-base;
  }
}

.stepper-checkbox {
  margin-bottom: 12px;

  .checkbox-right {
    margin-top: 12px !important;
    margin-bottom: 20px !important;
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;

    .checkbox {
      width: 6%;
    }

    input[type='checkbox'] {
      border: 1px solid $black-base;

      &:checked {
        &:after {
          left: 1px;
          top: 0px;
        }
      }
    }

    label a {
      font-size: clamp(14px, 1.8vw, 18px);
    }

    .checkbox-right {
      margin-top: 0px !important;

      li {
        font-family: 'Lato';
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: $black-base;
        margin-bottom: 8px;
        line-height: 24px;
        list-style-type: disc;
      }

      .privacy-content {
        margin-top: 20px;
        font-family: 'LatoRegular';
        font-size: 14px;
        padding: 0 0 0 5px;
      }

      .agree-link {
        font-family: 'LatoItalic';
        font-size: 14px;
        color: $blue-base;
        font-style: italic;

        &:hover {
          color: $blue-base;
        }

        &:active {
          color: $blue-base;
        }
      }
    }

    .terms-and-condition {
      margin-top: 15px !important;
    }
  }
}

.pre-qualification-installment {
  .first-title {
    color: $black-base;
    font-size: 30px;
    font-family: 'LatoBlack';
  }

  .first-subtitle {
    font-size: 20px;
    color: $black-base;
    font-family: 'LatoBlack';
    margin-bottom: 20px;
    margin-top: 48px;
  }

  .first-card {
    padding: 0px;
    border-radius: 10px;
    background-color: $lightgrey-lighter;
    margin: 40px 0;

    .first-card-header {
      padding: 30px;
      text-align: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: $red-base;

      &.purple {
        background-color: $purple-base;
      }
      .first-limit {
        color: $white-base;
        font-size: clamp(13px, 1.4vw, 16px);
        font-family: 'LatoRegular';
        margin-bottom: 0;
      }

      .first-price {
        color: $white-base;
        font-size: clamp(44px, 6vw, 60px);
        font-family: 'LatoRegular';
      }

      .first-detail {
        text-align: center;
        width: 75%;
        display: flex;
        margin: 0 auto;
        margin-top: 40px;
        font-size: 15px;
        line-height: 24px;
        font-family: 'LatoItalic';
      }
    }

    .first-card-body {
      padding: 20px;
      background-color: rgba($lightgrey-base, 0.15);

      .first-card-body-header {
        font-size: 20px;
        font-family: "'LatoBold'";
        color: $black-base;
        line-height: 26px;
      }

      .first-card-body-sub-header {
        font-size: 16px;
        font-family: "'LatoBold'";
        color: $black-base;
        line-height: 26px;
      }

      .first-card-icon-container {
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        svg {
          color: $red-base;
        }

        .first_tick {
          color: $red-base;
        }
      }

      p {
        font-size: 16px;
        font-family: 'LatoRegular';
        line-height: 24px;
      }

      .italic {
        margin-top: 40px;

        p {
          font-family: 'LatoItalic';
          font-size: 14px;
        }
      }
    }
    .advantage-approval-card {
      padding: 20px;
      background: $white-base;
      border: 1px solid $darkgrey-lighter;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px 4px 20px 0px rgba($black-base, 0.0784313725);
      .disclaimer-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 20px 0;
        .icon-container {
          aspect-ratio: 1/1;
          width: 36px;
          height: 36px;
          background-color: $lightblue-base;
          border-radius: 50%;
          padding: 8px;
          margin-right: 16px;
        }
        p {
          margin: 0px;
        }
      }
    }
    .credit-card-body {
      padding: 4vw;
      background: $white-base;
      border: 1px solid $darkgrey-lighter;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px 4px 20px 0px rgba($black-base, 0.0784313725);

      .credit-card--content {
        h3 {
          font-family: 'LatoRegular';
          font-size: clamp(20px, 2.4vw, 24px);
        }
        .key {
          color: $black-base;
        }
        .value {
          color: $black-base;
        }
      }
    }
  }

  .info-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 10px;

    svg {
      color: $black-light;
    }
    p {
      font-size: 20px;
    }
    .number-container {
      div {
        margin-bottom: 8px;
      }
      a {
        font-size: 20px !important;
        font-family: 'LatoRegular' !important;
        color: $darkgrey-base !important;
        text-decoration: none;
        font-weight: normal;
      }
    }
  }
}
.first-title {
  color: $black-base;
  font-size: 30px;
  font-family: 'LatoBlack';
}

.first-subtitle {
  font-size: 20px;
  color: $black-base;
  font-family: 'LatoBlack';
  margin-bottom: 20px;
  margin-top: 48px;

  .not-important {
    font-family: 'LatoRegular';
  }
}

.pre-qualification-card {
  .first-card {
    padding: 0px;
    border-radius: 10px;
    background-color: $lightgrey-lighter;
    margin-top: 55px;

    .first-card-header {
      padding: 30px;
      text-align: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: $lightgrey-base;

      .first-limit {
        color: $black-base;
        font-size: 14px;
        font-family: "'LatoBold'";
        margin-bottom: 25px;
      }

      .first-price {
        color: $red-base;
        font-size: 60px;
        font-family: "'LatoBold'";
      }

      .first-detail {
        text-align: center;
        width: 75%;
        display: flex;
        margin: 0 auto;
        margin-top: 40px;
        font-size: 15px;
        line-height: 24px;
        font-family: 'LatoItalic';
      }
    }

    .first-card-body {
      padding: 20px;
      background-color: rgba($lightgrey-base, 0.15);

      .first-card-body-header {
        font-size: 20px;
        font-family: "'LatoBold'";
        color: $black-base;
        line-height: 26px;
      }

      .first-card-body-sub-header {
        font-size: 16px;
        font-family: "'LatoBold'";
        color: $black-base;
        line-height: 26px;
      }

      .first-card-icon-container {
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        svg {
          color: $red-base;
        }

        .first_tick {
          color: $red-base;
        }
      }

      p {
        font-size: 16px;
        font-family: 'LatoRegular';
        line-height: 24px;
      }

      .italic {
        margin-top: 40px;

        p {
          font-family: 'LatoItalic';
          font-size: 14px;
        }
      }
    }

    .credit-card-body {
      padding: 20px 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 75%;
      margin: 0 auto;

      .credit-card--content {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .key {
          color: $black-base;
          font-family: "'LatoBold'";
          line-height: 40px;
          font-size: 20px;
        }

        .value {
          font-size: 20px;
          font-family: "'LatoBold'";
          line-height: 40px;
        }
      }
    }
  }
}

.stepper-card {
  margin-top: 50px;
  margin-bottom: 30px;
  border: 1px solid $black-light;

  .stepper-card-header {
    font-family: "'LatoBold'";
    background-color: $black-base;
    color: $white-base;
    font-size: 18px;
    padding: 10px;
  }

  .stepper-card-body {
    padding: 10px;
    height: 300px;

    .stepper-card-title {
      font-family: "'LatoBold'";
      font-size: 18px;
      color: $black-base;
    }

    p {
      font-family: 'LatoRegular';
      font-size: 14px;
    }

    ul li {
      font-family: 'LatoRegular';
    }
  }
}

.stepper-end {
  margin-top: 50px;

  p {
    color: $black-base;
    font-family: 'LatoLight';
    font-size: 16px;
  }
}

.add-auth {
  margin-top: 20px;
  font-size: 12px;
  font-family: 'LatoBlack';
}

.auth-contain p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'LatoRegular';
}

.last-card-body-header {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "'LatoBold'";
  color: $black-base;
}

.contact {
  font-size: 20px;
  font-family: 'LatoRegular';
}

.follow-us {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .follow {
    color: $darkgrey-base;
    font-size: 20px;
    font-family: 'LatoRegular';

    svg {
      color: $black-light;
    }
  }
}

.warn-disabled {
  cursor: not-allowed;
  background-color: $darkgrey-light;
  color: $white-base;
  border: 1px solid $darkgrey-light;
}

.warn-disabled:hover {
  cursor: not-allowed;
  background-color: $darkgrey-light;
  color: $white-base;
  border: 1px solid $darkgrey-light;
}

.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {
  color: $red-base;
}

.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: $red-base;
}

.MuiSvgIcon-root.social-icon path {
  fill: $red-base;
}

.ready-to-use {
  padding: 0 40px;

  li {
    font-family: 'LatoRegular';
    margin: 20px 0;
    line-height: 160%;
    font-size: clamp(14px, 1.6vw, 16px);
    color: $black-base;
    list-style-position: inside;
  }

  @media (max-width: 575.98px) {
    padding: 0 10px;
  }
}

div.MuiStepConnector-root {
  span.MuiStepConnector-line {
    border-color: $grey-light;
  }
}

.MuiTypography-root.MuiStepLabel-label {
  font-size: 14px;
  font-family: "'LatoBold'";
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
  margin-bottom: 70px;
}

.card-image {
  width: 150px;
  margin: -25px auto 0px;
  position: relative;
  top: 25px;
}

.prequal-link-account {
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  .__image {
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    img {
      max-width: 50%;
    }
  }

  .__body {
    font-family: monospace;
    line-height: 150%;
    font-size: 11px;
    color: $darkgrey-base;
    margin: 20px 0;
  }
}

.pre-qualification-installment {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  counter-reset: item; // Initialize counter

  .numbered-list {
    list-style: none;
    counter-reset: item;
    padding: 0;

    li {
      counter-increment: item;
      margin-bottom: 1rem;
      position: relative;
      padding-left: 2.5rem;
      list-style-type: none;

      &::before {
        content: counter(item);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        background-color: #e0e0e0;
        color: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }
    }
  }

  .next-steps {
    margin-top: 20px;

    h3 {
      font-size: 20px;
      font-family: 'LatoRegular';
      font-weight: 600;
      color: $black-base;
      margin-bottom: 20px;
    }

    p {
      font-size: clamp(18px, 2.2vw, 20px);
      font-family: 'LatoRegular';
      font-weight: 400;
    }
  }

  .checkbox {
    padding-top: 12px;
    width: 6%;
  }

  .aff-iframe {
    width: 100%;
    height: 700px;
  }

  h2 {
    font-size: clamp(24px, 3.2vw, 30px);
    color: $black-base;
    font-family: 'LatoRegular';
    margin-bottom: 20px;
    margin-top: 48px;
    font-weight: 700;
  }

  p {
    font-size: clamp(18px, 2.2vw, 22px);
    color: $black-base;
    font-family: 'LatoRegular';
    line-height: 160%;
    margin: 20px 40px;
  }

  input[type='checkbox'] {
    border: 1px solid $black-base;

    &:checked {
      &:after {
        left: 1px;
        top: 0px;
      }
    }
  }

  @media (max-width: 575.98px) {
    h2 {
      margin: 0 10px 20px;
    }
    p {
      margin: 10px 10px 0;
    }
  }

  .MuiIconButton-root {
    padding: 0;
  }
}

.tooltip-container {
  display: inline-block;
}

label .MuiSvgIcon-root,
.tooltip-container .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
label svg.MuiSvgIcon-root,
.tooltip-container svg.MuiSvgIcon-root {
  color: $white-base !important;
  background: $blue-base;
  border-radius: 20px;
}

.pre-qualification-installment-header {
  background-color: $lightgrey-light;
  margin-top: 55px;
  margin-bottom: 40px;
  font-size: clamp(18px, 1.8vw, 20px);
  font-weight: bold;
  color: $black-base;
  padding: 15px 15px 15px 40px;
  font-family: 'LatoRegular';
  border-radius: 5px;
}
.disclaimer {
  font-family: 'LatoRegular';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: $black-base;
  line-height: 24px;
  margin-top: 30px;
}

.installment-summary {
  display: flex;
  border: 1px solid $lightgrey-light;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px $grey-light;

  .summary {
    width: auto;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "'LatoRegular'";
    position: relative;
    overflow: hidden;

    .__ornament {
      background: $blue-base;
      height: 8px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .__body {
      font-family: 'LatoRegular';
      font-size: clamp(18px, 1.8vw, 20px);
      max-width: 45%;
      line-height: 160%;
    }
    .__divider {
      display: block;
      width: 2px;
      height: 80px;
      background: $grey-base;
    }
    .__image {
      max-width: 45%;
      text-align: center;
      img {
        width: 90%;
      }
    }

    @media (max-width: 575.98px) {
      flex-direction: column;
      row-gap: 40px;

      .__divider {
        width: 100%;
        height: 2px;
      }

      .__body {
        max-width: 100%;
        text-align: center;
      }
      .__image {
        max-width: 100%;
      }
    }
  }
}

.preQualification-form {
  .date-of-birth {
    width: 40%;
  }

  .authorized-buyer-text {
    font-size: 16px;
    line-height: 30px;
    font-family: 'LatoRegular';
    margin-top: 10px;
  }
}

.terms-frame {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.print-button {
  background: none;
  border: 2px solid $red-base;
  border-radius: 30px;
  color: $red-base;
  text-transform: uppercase;
  width: 140px;
  height: 40px;
  cursor: pointer;

  &:hover {
    border: 2px solid $red-base;
    background: $red-base;
    color: $white-base;
  }
}

@media (max-width: 575.98px) {
  .wizard {
    .stepper-container {
      .MuiPaper-root {
        width: 100%;
        padding: 20px 20px 35px;
      }

      .steppper-header {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 16px;
        font-family: "'LatoBold'";
        padding-top: 15px;
      }

      .MuiStepLabel-labelContainer {
        display: none;
      }
    }

    .content {
      width: 85%;
    }

    .one-line-text {
      flex-direction: column;
      gap: unset;
    }

    .button-warn {
      width: 100%;
      padding: 15px;
      font-size: 12px;
    }

    .print-button,
    .add-auth,
    .date-birth {
      width: 100%;
    }
  }

  .first-card {
    .credit-card-body {
      width: unset;
      padding: 20px 20px;

      .credit-card--content {
        gap: unset;
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
  }

  .wizard-header-title {
    display: none !important;
  }
}

.radio-group {
  &__share {
    fieldset {
      width: 100% !important;
      margin-bottom: 10px !important;

      fieldset {
        display: flex;
        justify-content: flex-start;
        width: 100% !important;
        padding-top: unset !important;
        gap: 10px;

        label {
          color: $black-base;
          font-family: 'LatoRegular';
          font-size: 16px;
          font-weight: normal;
          opacity: 0.75;
        }

        input[type='radio'] {
          -webkit-appearance: radio;
          appearance: none;
          background-color: $white-base;
          margin: 0;
          color: $black-base;
          width: 22px;
          height: 22px;
          border: 2.5px solid $black-base !important;
          padding: unset !important;
          border-radius: 50%;
          transform: translateY(-0.075em);

          display: grid;
          place-content: center;
        }

        input[type='radio']::before {
          content: '';
          width: 13px;
          height: 14px;
          border-radius: 50%;
          transform: scale(0);
          background-color: $black-base;
          transition: 120ms transform ease-in-out;
          background-color: CanvasText;
        }

        input[type='radio']:checked::before {
          transform: scale(1);
        }
      }
    }
  }
}
.section-header {
  text-align: left;
  background-color: $lightgrey-light;
  margin-top: 55px;
  margin-bottom: 40px;
  font-size: clamp(18px, 1.8vw, 20px);
  font-weight: bold;
  color: $black-base;
  padding: 15px 15px 15px 40px;
  font-family: 'LatoRegular';
  border-radius: 5px;

  @media (max-width: 575.98px) {
    padding: 15px 10px;
  }
}

span.term-link {
  display: inline-block;
  margin-right: 10px;
}

.check-phone-image {
  width: 180px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  .phone-image {
    width: 106.85px;
    margin: 0 auto;
    position: relative;
    bottom: -50px;
    z-index: 10;
  }
}
.profile-table {
  max-width: 600px;
  .profile-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    div ~ div {
      width: 48%;
      text-align: left;
    }
  }
}

.phone-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin-left: -25px;
}
.animate-check-phone-image {
  display: flex;
  column-gap: 5px;
}
.animation_ball_bounce {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  background: $purple-base;
}

.edit-button-icon {
  height: 100%;
  width: 24px;
  margin-right: 10px;
}
.edit-button-span {
  width: 200px;
}

.access-offer-estimate {
  border: 1px solid $lightgrey-base;
  box-shadow: 0px 4px 20px 0px rgba($black-base, 0.08);
  border-radius: 8px;
  width: 766px;
  padding: 36px 30px;
  margin-top: 24px;
  h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: "'LatoBold'";
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
    font-family: 'LatoRegular';
    color: $darkgrey-base;
  }
  .calculator-output {
    border: 1px solid $lightgrey-base;
    border-top: 6px solid $blue-base;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px;
    .column-left,
    .column-right {
      height: 142px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .column-left {
      width: calc(50% - 1px);
      border-right: 1px solid $grey-base;
      h5 {
        font-size: 14px;
        line-height: 24px;
        font-family: "'LatoBold'";
        color: $black-base;
        margin: 0;
        padding: 6px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        font-family: 'LatoRegular';
        color: $black-base;
        padding: 4px;
        margin: 0;
      }

      img {
        width: 159px;
        height: auto;
        margin-bottom: 10px;
      }
    }
    .column-right {
      h5 {
        font-size: 16px;
        line-height: 20px;
        font-family: 'LatoRegular';
      }
      p {
        font-size: 14px;
        line-height: 20px;
        font-family: 'LatoRegular';
        color: $darkgrey-base;
        margin: 0;
      }
      .payment-amount {
        font-family: "'LatoBold'";
        font-size: 56px;
        color: $black-base;
        line-height: 67px;
      }
    }
    .column-right {
      width: 50%;
    }
  }
  .access-offer-estimate-calculator {
    margin-top: 30px;
    input {
      font-size: 16px;
      font-family: 'LatoRegular';
      padding-left: 20px !important;
    }
    .pre-icon-container {
      font-size: 16px;
      font-family: 'LatoRegular';
      color: $black-base;
    }
    fieldset {
      width: 50%;
      margin-bottom: 20px;
      label {
        font-size: 16px;
        font-family: "'LatoBold'";
      }
    }
    button {
      width: 50%;
    }
  }
}

.payment-frequency-body {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .field-container {
      width: 48%;
      margin: 6px 0;
      select {
        margin-bottom: 3rem;
      }
    }
  }
}

@media (max-width: 766px) {
  .access-offer-estimate {
    width: 100%;
    min-width: 400px;
    .calculator-output {
      flex-direction: column;
      padding: 12px;
      .column-left {
        width: 100%;
        padding: 0 0 12px 0;
        border-right: none;
        border-bottom: 1px solid $grey-base;
      }
      .column-right {
        width: 100%;
        padding: 12px 0 0 0;
      }
    }
    .access-offer-estimate-calculator {
      fieldset {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }
  .payment-frequency-body {
    .row {
      flex-direction: column;
      .field-container {
        width: 100%;
      }
    }
  }
}

.offer-card {
  margin: 20px 0;
  padding: 30px;
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    button.button-warn {
      min-width: 187.36px;
    }
    .logo-and-middle {
      width: 75%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      img {
        width: 200px;
        height: 46px;
        padding-right: 12px;
      }
      .middle-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
        padding: 0 18px;
        margin-top: 12px;
      }
      .status-and-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .empty-amount {
        height: 32px;
      }
      .empty-disclaimer {
        height: 16px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    .content-container {
      flex-direction: column;
      button.button-warn {
        width: 100%;
      }
      .logo-and-middle {
        margin-bottom: 18px;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        .middle-container {
          margin-top: 0;
        }
        img {
          width: 50%;
          min-width: 136px;
          height: auto;
        }
        .status-and-amount {
          flex-direction: column;
          align-items: flex-start;
          .status-text,
          .declined-text {
            font-size: 12px;
          }
          .prequal-amount {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.offers-container {
  font-family: "'LatoBold'";
  max-width: 1050px !important;
  margin: 0 auto;
}
